import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../services/apiService';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';

function SelectImportSetup() {
    const { authId } = useParams();

    const [loading, setLoading] = useState(true);
    const [auth, setAuth] = useState({});
    const [selectedImport, setSelectedImport] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        loadInitData();
        setLoading(false);
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once
      
    const loadInitData = async () => {
        var _entity = await fetchData(`/authentication/${authId}`);
        setAuth(_entity);
    };

    const cancel = async () => {
        navigate(-1);
    };

    const selectOption = async () => {

        if (!selectedImport){
            alert("Please select an option");
            return;
        }
        setLoading(true);
        var sessionData = JSON.parse(localStorage.getItem("session"));
        //add scenario to this import id
        await postData(`/imports/${selectedImport.id}/addScenario`, { 
            "PGOCompanyId": sessionData.pgoCompanyId,
            "PGOScenarioId": sessionData.pgoScenarioId,
            "PGOProjectId": sessionData.pgoProjectId,
        });
        await postData(`/imports/${selectedImport.id}/run`);

        window.location.href = `${PGO_REDIRECT_URL}?importZeroBalanceAccounts=${selectedImport.importZeroBalanceAccounts}&authenticationId=${authId}&importId=${selectedImport.id}&companyName=${auth.qboCompanyName}&class=${selectedImport.classFilterName}&department=${selectedImport.locationFilterName}&customer=${selectedImport.customerFilterName}&autoSync=${auth.autoSync}`;
    };

    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">Loading...</p>
            </section>
        );
    }else{
        return (
            <div className="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                <div className="text-xl font-medium text-black mb-4">{auth.qboCompanyName} Import Setup</div>
                <div class="flex mt-8">
                    <table class="table-auto border">
                        <thead class="border">
                            <tr>
                            <th class="px-4 py-2">Select</th>
                            <th class="px-4 py-2">Class</th>
                            <th class="px-4 py-2">Location</th>
                            <th class="px-4 py-2">Customer</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y">
                        {auth && Array.isArray(auth.imports) && auth.imports.map((item) => (
                            <tr class="divide-x" key={item.id}>
                                <td class="px-4 py-2">
                                    <input type="radio" name="selectedImport" checked={selectedImport == item} value={item.id} onChange={() => setSelectedImport(item)} />
                                </td>
                                <td class="px-4 py-2">{item.classFilterName ? item.classFilterName : "None"}</td>
                                <td class="px-4 py-2">{item.locationFilterName ? item.locationFilterName : "None"}</td>
                                <td class="px-4 py-2">{item.customerFilterName ? item.customerFilterName : "None"}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex items-center mt-8">
                    <button className="mr-2 py-2 px-2 border border-black-500 font-bold rounded" onClick={cancel}>
                        Cancel
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-700 mr-2 py-2 px-2 text-white font-bold rounded" onClick={selectOption}>
                        Select
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-700 py-2 px-2 text-white font-bold rounded" onClick={() => navigate(`/import-setup/${auth.id}`)}>
                        Add Setup
                    </button>
                </div>
            </div>
          );
    }
}

export default SelectImportSetup;
