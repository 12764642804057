import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../services/apiService';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';

function SelectCompanyQBD() {
    const { pgoCompanyId } = useParams();

    const [loading, setLoading] = useState(true);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        loadCompanies();
        setLoading(false);
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once
      
    const loadCompanies = async () => {

        var sessionData = JSON.parse(localStorage.getItem("session"));
        
        var _entities = await fetchData(`/qbd/list-companies/${sessionData.pgoCompanyId}`);
        setCompanyList(_entities);
    };

    const cancel = async () => {
        window.location.href = PGO_REDIRECT_URL;
    };

    const selectOption = async () => {

        if (!selectedCompany){
            alert("Please select an option");
            return;
        }
        //check if any scenarios linked to this selected Company Record

        var _scenarios = await fetchData(`/qbd/scenarios/${selectedCompany.id}`);

        const isScenariosEmpty = _scenarios && Array.isArray(_scenarios) && _scenarios.length == 0;
        console.log(isScenariosEmpty);
        if (isScenariosEmpty){
            //if no scenarios, go to import setup
            navigate(`/desktop-import-setup/${selectedCompany.id}`);
        }else{
            //if scenarios exist, go to select import setup
            navigate(`/desktop-select-import-setup/${selectedCompany.id}`);
        }
        
    };

    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">Loading...</p>
            </section>
        );
    }else{
        return (
            <div className="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                <div className="text-xl font-medium text-black mb-4">Select QuickBooks Desktop Company</div>
                <div class="flex mt-8">
                    <table class="table-auto border">
                        <thead class="border">
                            <tr>
                                <th class="px-4 py-2">Select</th>
                                <th class="px-4 py-2">Company Name</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y">
                        {companyList.map((item) => (
                            <tr class="divide-x" key={item.id}>
                                <td class="px-4 py-2">
                                    <input type="radio" name="selectedCompany" checked={selectedCompany == item} value={item.id} onChange={() => setSelectedCompany(item)} />
                                </td>
                                <td class="px-4 py-2">{item.qbdCompanyName}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex items-center mt-8">
                    <button className="mr-2 py-2 px-2 border border-black-500 font-bold rounded" onClick={cancel}>
                        Cancel
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-700 mr-2 py-2 px-2 text-white font-bold rounded" onClick={selectOption}>
                        Select
                    </button>
                </div>
            </div>
          );
    }
}

export default SelectCompanyQBD;
