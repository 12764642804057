import axios from 'axios';
import { BASE_API_URL } from '../core/constants';

const apiService = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchData = async (endpoint) => {
  try {
    const response = await apiService.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const postData = async (endpoint, data) => {
  try {
    const response = await apiService.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
  }
};

export const putData = async (endpoint, data) => {
    try {
      const response = await apiService.put(endpoint, data);
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };
