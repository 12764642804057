import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../services/apiService';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';

function EditImportSetupQBD() {
    const [loading, setLoading] = useState(true);
    const [zeroBalanceAccounts, setZeroBalanceAccounts] = useState();
    const [inactiveAccounts, setInactiveAccounts] = useState();
    const [historicalData, setHistoricalData] = useState();
    const [filterList, setFilterList] = useState([]);
    const [filter, setFilter] = useState({});
    const [importOption, setImportOption] = useState();
    const [accountingMethod, setAccountingMethod] = useState(1);
    const [authEntity, setAuthEntity] = useState();
    const [applyFilters, setApplyFilters] = useState(false);
    const [importEntity, setImportEntity] = useState({});
    const navigate = useNavigate();
    const { importId } = useParams();

    useEffect(() => {
        initApp();
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once

    const initApp = async () => {
        var _importEntity = await fetchData(`/qbd/import/${importId}`);
        setImportEntity(_importEntity);

        var _filterList = await fetchData(`/qbd/filters/${_importEntity.qbdCompanyId}`);
        setFilterList(_filterList);

        var _filterEntity = _filterList.find((cls) => cls.listId == _importEntity.filterSelection);
        if (_filterEntity)
            setFilter(_filterEntity);

        setZeroBalanceAccounts(_importEntity.importZeroBalanceAccounts);
        setHistoricalData(_importEntity.importHistoricalPeriods);
        setImportOption(_importEntity.importStatements);
        setAccountingMethod(_importEntity.accountingMethod);

       setLoading(false);
    };

    const cancel = async () => {
        window.location.href = PGO_REDIRECT_URL;
    };

    const saveAndReturn = async () => {
        setLoading(true);
        var importResult = await save();
        if (importResult == null)
        {
            alert("Error saving import settings");
            setLoading(false);
            return;
        }

        window.location.href = `${PGO_REDIRECT_URL}?`;
    };

    const save = async () => {
        var sessionData = JSON.parse(localStorage.getItem("session"));
        var importResult = await postData(`/qbd/imports/update`, {
            "importId": importId,
            "importZeroBalanceAccounts": zeroBalanceAccounts,
            "importInactiveAccounts": inactiveAccounts,
            "importHistoricalPeriods": historicalData,
            "importType": importOption,
            "accountingMethod": accountingMethod,
            "filterDesc": filter.name,
            "filterSelection": filter.listId
        });

        return importResult;
    };

    const updateFilter = async (id) => {
        var entity = filterList.find((cls) => cls.listId === id);
        setFilter(entity);
    };

    const toggleFilters = (filtersEnabled) =>
    {
        setApplyFilters(filtersEnabled);
        
        if (!filtersEnabled)
        {
            filter.listId = "";
            setFilter({});
        }
    }


    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
            </section>
        );
    }else{
        return (
            <>
                <div class="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                    <div class="text-xl font-medium text-black mb-4">QuickBooks Desktop Import Setup</div>
                    <div class="flex items-center">
                        <input type="checkbox" id="ImportZeroBalanceAccounts" name="ImportZeroBalanceAccounts" class="form-checkbox mr-2" checked={zeroBalanceAccounts} onChange={(event) => setZeroBalanceAccounts(event.target.checked)} />
                        <label for="ImportZeroBalanceAccounts">Import zero balance accounts</label>
                    </div>
                    {/* <div class="flex items-center">
                        <input type="checkbox" id="ImportInactiveAccounts" name="ImportInactiveAccounts" class="form-checkbox mr-2" checked={inactiveAccounts} onChange={(event) => setInnactiveAccounts(event.target.checked)} />
                        <label for="ImportInactiveAccounts">Import inactive accounts</label>
                    </div> */}
                    <div class="flex items-center">
                        <input type="checkbox" id="syncHistoricalData" name="syncHistoricalData" class="form-checkbox mr-2" checked={historicalData} onChange={(event) => setHistoricalData(event.target.checked)}/>
                        <label for="syncHistoricalData">Sync historical data after initial import</label>
                    </div>
                    <div class="flex items-center mt-4">
                        <input disabled={true} type="checkbox" class="form-checkbox mr-2" checked={applyFilters} onChange={(event) => toggleFilters(event.target.checked)} />
                        <label>Apply filters:</label>
                    </div>
                    <div class="flex">
                        <table class="table-auto">
                            <thead>
                                <tr>
                                    <th class="px-4 py-2"></th>
                                    <th class="px-4 py-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="px-4 py-2">Filter Selection</td>
                                    <td class="px-4 py-2">
                                        <select
                                            disabled={true}
                                            class="border border-gray-400 px-2 py-1 rounded"
                                            value={filter.listId}
                                            onChange={(e) => updateFilter(e.target.value)}
                                        >
                                            <option value="">-- none selected --</option>
                                            {filterList.map((item) => (
                                                <option key={item.listId} value={item.listId}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="flex items-center mt-4">
                        <label for="timezone" class="mr-2">Accounting Method:</label>
                        <input
                            class="mr-2"
                            type="radio"
                            value="1"
                            name="accountingMethod"
                            checked={accountingMethod === 1}
                            onChange={(e) => setAccountingMethod(Number(e.target.value))}
                        /> <span class="mr-2">Accrual</span>
                        <input 
                            class="mr-2"
                            type="radio"
                            value="2"
                            name="accountingMethod"
                            checked={accountingMethod === 2}
                            onChange={(e) => setAccountingMethod(Number(e.target.value))}
                        /> Cash
                    </div>
                    <div class="flex items-center mt-4">
                        <label for="timezone" class="mr-2">Import Options</label>
                        <select id="timezone" name="timezone" class="border border-gray-400 px-2 py-1 rounded"
                        value={importOption}
                        onChange={(e) => setImportOption(parseInt(e.target.value, 10))}>
                            <option selected value="0">Both</option>
                            <option value="1">Balance Sheet</option>
                            <option value="2">Income Statement</option>
                        </select>
                    </div>
                    <div class="flex items-center mt-8">
                        <button class="mr-4 py-2 px-2 border border-black-500 font-bold rounded" onClick={cancel}>
                            Cancel
                        </button>
                        <button class="bg-blue-500 hover:bg-blue-700 mr-4 py-2 px-2 text-white font-bold rounded" onClick={saveAndReturn}>
                            Save
                        </button>
                    </div>
                </div>
            </>
          );
    }
}

export default EditImportSetupQBD;
