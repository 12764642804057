import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL, BASE_API_URL } from '../core/constants';

function DownloadQWC() {
    const [filterSelection, setFilterSelection] = useState(0);
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        
      }, []);

    const downloadFile = async () => {
        var session = JSON.parse(localStorage.getItem('session'));

        //Navigate to a endpoint that downloads Zip File from API
        window.open(`${BASE_API_URL}/qbd/download/${session.pgoCompanyId}/${session.fileId}/${session.ownerId}`, '_blank');
    };

    const cancel = async () => {
        window.location.href = PGO_REDIRECT_URL;
    };

    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">Downloading File...</p>
            </section>
        );
    }else{
        return (
            <div className="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                <div className="text-xl font-medium text-black mb-4">Download QuickBooks Desktop Connector File</div>
                <div className="flex items-center">
                    <p>To import data from QuickBooks Desktop the QuickBooks Connector utility needs to be installed on the same computer where QuickBooks is installed.  Click on the Download button to download a zip file which includes the “qwc” file which is needed to install the QuickBooks Connector and instructions for installing the QuickBooks Connector.</p>>
                </div>
                <div className="flex items-center mt-8">
                    <button className="mr-2 py-2 px-2 border border-black-500 font-bold rounded" onClick={cancel}>
                        Cancel
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-700 mr-2 py-2 px-2 text-white font-bold rounded" onClick={downloadFile}>
                        Download File
                    </button>
                </div>
            </div>
            );
    }
}

export default DownloadQWC;
