import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData, putData } from '../services/apiService';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';

function ImportConnectionOptions() {
    const [loading, setLoading] = useState(false);
    const [authEntity, setAuthEntity] = useState({});
    const [importEntity, setImportEntity] = useState({});
    const [lastImportDate, setLastImportDate] = useState();
    const [progressMessage, setProgressMessage] = useState("Loading...");
    const [pendingJobs, setPendingJobs] = useState([]);
    const navigate = useNavigate();
    const { authId, importId } = useParams();
    const location = useLocation();

    useEffect(() => {
        setupComponent();
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once


    const setupComponent = async () => {
        var _entity = await fetchData(`/authentication/${authId}`)
        setAuthEntity(_entity);

        var _importEntity = await fetchData(`/imports/${importId}`);
        setImportEntity(_importEntity);

        if(_importEntity?.lastSuccessfulDateSynced){ 
            var lastSuccessfulDate = _importEntity?.lastSuccessfulDateSynced;
            
            // Create a new Date object from lastSuccessfulDate
            var localDateString = getTimezoneAdjustedDate(lastSuccessfulDate, _entity.timeZoneOffset);
            setLastImportDate(localDateString);
        }else{
            setLastImportDate("never");
        }
    };

    const getTimezoneAdjustedDate = (dateValue, offset) =>{
        const date = new Date(dateValue);

        // Get the UTC time
        const utcTime = date.getTime();

        const localTime = new Date(utcTime - ((offset * 60 * 60 * 1000)*-1));

        // Get the local date string
        const localDateString = localTime.toLocaleDateString();

        return localDateString;
    };

    const disconnectQuickbooks = async () => {
        const updatedAuthEntity = { ...authEntity, active: false };
        await putData(`/authentication/${authId}`, updatedAuthEntity);

        setAuthEntity(updatedAuthEntity);

        alert("Quickbooks Account Disconnected");
    };

    const close = async () => {
        window.location.href = `${PGO_REDIRECT_URL}?importZeroBalanceAccounts=${importEntity.importZeroBalanceAccounts}&authenticationId=${authId}&importId=${importId}&companyName=${authEntity.qboCompanyName}&class=${importEntity.classFilterName}&department=${importEntity.locationFilterName}&customer=${importEntity.customerFilterName}&autoSync=${authEntity.autoSync}`;
    };

    const editAuth = async () => {
        navigate(`/authenticate?authId=${authId}`);//TODO pass this as optional. fetch data if exists
    };

    const editImportSetup = async () => {
        navigate(`/edit-import-setup/${authId}/${importId}`);
    };

    const getJobImportType = (importType) => {
        switch (importType)
        {
            case 1:
                return "Processing Balance Sheet...";
                break;
            case 2:
                return "Processing Income Statement...";
                break;
        }
    };

    const syncScenario = async () => {
        setLoading(true);
        setProgressMessage("Import in Progress...");
        setLastImportDate(new Date().toLocaleDateString());

        await postData(`/imports/${importId}/run`);

        let intervalId = setInterval(async () => {
            var jobs = await fetchData(`/imports/${importId}/jobs/in-progress`);
            setPendingJobs(jobs);
            if (!jobs || jobs.length === 0) {
                clearInterval(intervalId); // Stop the interval
                setLoading(false);
                alert("Import Completed!");
            }
        }, 2000); // 10 seconds interval
    };

    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">{progressMessage}</p>
                    {pendingJobs.map(job => (
                        <div key={job.id} className="mt-4 text-gray-700">{getJobImportType(job.importType)}</div>
                    ))}
            </section>
        );
    }else{
        return (
            <>
                <div class="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                    <div class="text-xl font-medium text-black mb-4">Connection Options | {authEntity.qboCompanyName}</div>
                    <div class="flex mt-4 items-center">
                        <button disabled={!authEntity.active} class={`mr-4 py-2 px-2 border border-black-500 font-bold rounded ${!authEntity.active ? 'bg-gray-200' : ''}`} onClick={syncScenario}>
                            Sync Scenario with QuickBooks
                        </button>
                    </div>
                    <div class="flex mt-4 items-center">
                        <button class="mr-4 py-2 px-2 border border-black-500 font-bold rounded" onClick={editImportSetup}>
                            Edit Import Setup
                        </button>
                    </div>
                    <div class="flex mt-4 items-center">
                        <button class="mr-4 py-2 px-2 border border-black-500 font-bold rounded" onClick={editAuth}>
                            Edit QuickBooks Online Connection
                        </button>
                    </div>
                    <div class="flex mt-4 items-center">
                        <button disabled={!authEntity.active} class={`mr-4 py-2 px-2 border border-black-500 font-bold rounded ${!authEntity.active ? 'bg-gray-200' : ''}`} onClick={disconnectQuickbooks}>
                            Disconnect from QuickBooks
                        </button>
                    </div>
                    <div class="flex mt-4 items-center">
                        <button  class="mr-4 py-2 px-2 border border-black-500 font-bold rounded" onClick={close}>
                            Close
                        </button>
                    </div>
                    <div class="flex mt-4 items-center">
                        <p>Date Last Synced: {lastImportDate}</p>
                    </div>
                </div>
            </>
          );
    }
}

export default ImportConnectionOptions;