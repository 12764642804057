import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../services/apiService';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';

function CompleteAuthentication() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    // Get specific query parameters
    const _verifyCode = queryParams.get('code');
    const _realMid = queryParams.get('realmId');

    const navigate = useNavigate();

    useEffect(() => {
            saveAuthentication();
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []);

    const saveAuthentication = async () => {
        try {
            var sessionData = JSON.parse(localStorage.getItem('session'));

            var company = await postData(`/qbo/auth/save`, {
                "realmid": _realMid,
                "verifyCode": _verifyCode,
                "pgoCompanyId": sessionData.pgoCompanyId,
                "isUnique": sessionData.isUnique,
                "autoSync": sessionData.autoSync,
                "timeZoneOffset": sessionData.timeZoneOffset
            });

            console.log("saved and done with Auth");

            //TODO need to navigate somewhere
            //import-setup
            navigate(`/import-setup/${company.id}`);
            
        } catch (error) {
            // Handle error
        }
    };

  return (
    <section className="flex flex-col items-center mt-16">
        <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
        <p className="mt-4 text-gray-700">Loading...</p>
    </section>
  );
}

export default CompleteAuthentication;
