import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData, putData } from '../services/apiService';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';

function DesktopSetup() {
    const [filterSelection, setFilterSelection] = useState(0);
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        
      }, []);

    const saveConnection = async () => {
        try {
            setLoading(true);
            var session = JSON.parse(localStorage.getItem('session')); 

            var formData = {
                "sessionId": session.sessionId,
                "pgoCompanyId": session.pgoCompanyId,
                "pgoScenarioId": session.pgoScenarioId,
                "importFilter": filterSelection,
                "username": username,
                "password": password
            };

            var postResponse = await postData(`/qbd/connection`, formData);

            var sessionData = {
                "sessionId": session.sessionId,
                "pgoCompanyId": session.pgoCompanyId,
                "pgoScenarioId": session.pgoScenarioId,
                "fileId": postResponse.fileId,
                "ownerId": postResponse.ownerId
            };
    
            localStorage.setItem('session', JSON.stringify(sessionData));

            navigate(`/download-qwc`);
        } catch (error) {
            setLoading(false);
            alert("Error Saving Connection Data");
        }
    };

    const cancel = async () => {
        window.location.href = PGO_REDIRECT_URL;
    };

    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">Saving...</p>
            </section>
        );
    }else{
        return (
            <div className="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                <div className="text-xl font-medium text-black mb-4">QuickBooks Desktop Connection Setup</div>
                <div className="flex items-center">
                    <label>Will you be filtering import data by Class or Job?</label>
                    <select className="ml-4 border border-gray-400 px-2 py-1 rounded" value={filterSelection} onChange={(event) => setFilterSelection(event.target.value)}>
                        <option value="0">No</option>
                        <option value="1">Classes</option>
                        <option value="2">Jobs</option>
                    </select>
                </div>
                <div className="flex items-center space-x-4 mt-4">
                <label className="w-48 text-right">QuickBooks Admin Username</label>
                <input 
                    type="text" 
                    className="w-full p-2 border rounded-md" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                />
                </div>
                <div className="flex items-center space-x-4 mt-4">
                    <label className="w-48 text-right">QuickBooks Password</label>
                    <input 
                        type="password" 
                        id="password" 
                        className="w-full p-2 border rounded-md" 
                        placeholder="•••••••••" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                </div>
                <div className="flex items-center mt-8">
                    <button className="mr-2 py-2 px-2 border border-black-500 font-bold rounded" onClick={cancel}>
                        Cancel
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-700 mr-2 py-2 px-2 text-white font-bold rounded" onClick={saveConnection}>
                    Save
                    </button>
                </div>
            </div>
            );
    }
}

export default DesktopSetup;
