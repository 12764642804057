import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../services/apiService';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';

function ImportAppQBD() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    // Get specific query parameters
    const pgoCompanyId = queryParams.get('companyId');
    const pgoScenarioId = queryParams.get('scenarioId');

    const sessionId = uuidv4();

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        try{
         initApp();
        }catch(error){
         console.log("error on init app: ", error);
        }
        
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once

    const initApp = async () => {

        //this should be looking for an authenticated pgoCompanyId record
        var authEntity = await fetchData(`/qbd/authentication/pgo/${pgoCompanyId}/${pgoScenarioId}`);

        var sessionData = {
            "sessionId": sessionId,
            "pgoCompanyId": pgoCompanyId,
            "pgoScenarioId": pgoScenarioId,
            "fileId": authEntity?.fileId,
            "ownerId": authEntity?.ownerId
        };

        localStorage.setItem('session', JSON.stringify(sessionData));

        if (!authEntity){
            //no auth record found, go to First call from PGO
            navigate(`/desktop-setup`);
            return;
        }else if (!authEntity.connEstablished){
            navigate(`/download-qwc`);
            return;
        }else{
            //check if scenario exists for this authEntity
            const isScenariosEmpty = authEntity && Array.isArray(authEntity.scenarios) && authEntity.scenarios.length === 0;

            if (isScenariosEmpty){
                //no scenarios
                navigate(`/desktop-select-company`);
            }else if (authEntity.scenarios.some(scenario => scenario.pgoScenarioId == pgoScenarioId)){
                //the scenario already exists
                const matchingScenario = authEntity.scenarios.find(
                    scenario => scenario.pgoScenarioId == pgoScenarioId
                  );
                  console.log(matchingScenario);
                navigate(`/desktop-connection-options/${matchingScenario.qbdImportsId}`);
            }else{
                //the scenario does not exist
                navigate(`/desktop-select-company`);
            }
        }

        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">Loading...</p>
            </section>
        );
    }
}

export default ImportAppQBD;
