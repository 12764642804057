import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../services/apiService';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';

function ImportSetup() {
    const [loading, setLoading] = useState(true);
    const [zeroBalanceAccounts, setZeroBalanceAccounts] = useState();
    const [inactiveAccounts, setInnactiveAccounts] = useState();
    const [historicalData, setHistoricalData] = useState();
    const [classList, setClassList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [classFilter, setClassFilter] = useState({});
    const [locationFilter, setLocationFilter] = useState({});
    const [customerFilter, setCustomerFilter] = useState({});
    const [importOption, setImportOption] = useState();
    const [accountingMethod, setAccountingMethod] = useState(1);
    const [authEntity, setAuthEntity] = useState();
    const [applyFilters, setApplyFilters] = useState(false);
    const [progressMessage, setProgressMessage] = useState("Loading...");
    const [pendingJobs, setPendingJobs] = useState([]);
    const [sessionId, setSessionId] = useState(localStorage.getItem('session'));
    const navigate = useNavigate();
    const { authId } = useParams();

    useEffect(() => {
        initApp();
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once

    const initApp = async () => {
        var cList = await fetchData(`/qbo/filters/${authId}/classes`);
        setClassList(cList);

        var dList = await fetchData(`/qbo/filters/${authId}/departments`);
        setLocationList(dList);

        var cuList = await fetchData(`/qbo/filters/${authId}/customers`);
        setCustomerList(cuList);

        var _authEntity = await fetchData(`/authentication/${authId}`);
        setAuthEntity(_authEntity);

       setLoading(false);
    };

    const cancel = async () => {
        window.location.href = PGO_REDIRECT_URL;
    };

    const saveAndReturn = async () => {
        setLoading(true);
        var importResult = await save();
        if (importResult == null)
        {
            alert("Error saving import settings");
            setLoading(false);
            return;
        }

        window.location.href = `${PGO_REDIRECT_URL}?importZeroBalanceAccounts=${importResult.importZeroBalanceAccounts}&authenticationId=${authId}&importId=${importResult.id}&companyName=${authEntity.qboCompanyName}&class=${importResult.classFilterName}&department=${importResult.locationFilterName}&customer=${importResult.customerFilterName}&autoSync=${authEntity.autoSync}`;
    };

    const saveAndImport = async () => {
        setLoading(true);
        setProgressMessage("Import in Progress...");
        var importResult = await save();

        await postData(`/imports/${importResult.id}/run`);

        let intervalId = setInterval(async () => {
            var jobs = await fetchData(`/imports/${importResult.id}/jobs/in-progress`);
            setPendingJobs(jobs);
            if (!jobs || jobs.length === 0) {
                clearInterval(intervalId); // Stop the interval
                window.location.href = `${PGO_REDIRECT_URL}?importZeroBalanceAccounts=${importResult.importZeroBalanceAccounts}&authenticationId=${authId}&importId=${importResult.id}&companyName=${authEntity.qboCompanyName}&class=${importResult.classFilterName}&department=${importResult.locationFilterName}&customer=${importResult.customerFilterName}&autoSync=${authEntity.autoSync}`;
            }
        }, 5000); // 10 seconds interval

    };

    const save = async () => {
        var sessionData = JSON.parse(localStorage.getItem("session"));

        var importResult = await postData(`/imports`, {
            "authenticationId": authId,
            "PGOCompanyId": sessionData.pgoCompanyId,
            "PGOScenarioId": sessionData.pgoScenarioId,
            "PGOProjectId": sessionData.pgoProjectId,
            "importZeroBalanceAccounts": zeroBalanceAccounts,
            "importInactiveAccounts": inactiveAccounts,
            "importHistoricalPeriods": historicalData,
            "importType": importOption,
            "classFilterName": classFilter.fullyQualifiedName,
            "classFilterId": classFilter.id,
            "locationFilterName": locationFilter.fullyQualifiedName,
            "locationFilterId": locationFilter.id,
            "customerFilterName": customerFilter.fullyQualifiedName,
            "customerFilterId": customerFilter.id,
            "accountingMethod": accountingMethod
            
        });

        return importResult;
    };

    const updateClassFilter = async (id) => {
        var entity = classList.find((cls) => cls.id === id);
        setClassFilter(entity);
    };

    const updateLocationFilter = async (id) => {
        var entity = locationList.find((cls) => cls.id === id);
        setLocationFilter(entity);
    };

    const updateCustomerFilter = async (id) => {
        var entity = customerList.find((cls) => cls.id === id);
        setCustomerFilter(entity);
    };

    const getJobImportType = (importType) => {
        switch (importType)
        {
            case 1:
                return "Processing Balance Sheet...";
                break;
            case 2:
                return "Processing Income Statement...";
                break;
        }
    };

    const toggleFilters = (filtersEnabled) =>
    {
        setApplyFilters(filtersEnabled);
        
        if (!filtersEnabled)
        {
            locationFilter.id = "";
            classFilter.id = "";
            customerFilter.id = "";
            setLocationFilter({});
            setClassFilter({});
            setCustomerFilter({});
        }
    }


    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">{progressMessage}</p>
                    {pendingJobs.map(job => (
                        <div key={job.id} className="mt-4 text-gray-700">{getJobImportType(job.importType)}</div>
                    ))}
            </section>
        );
    }else{
        return (
            <>
                <div class="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                    <div class="text-xl font-medium text-black mb-4">QuickBooks Online Import Setup</div>
                    <div class="flex items-center">
                        <input type="checkbox" id="ImportZeroBalanceAccounts" name="ImportZeroBalanceAccounts" class="form-checkbox mr-2" checked={zeroBalanceAccounts} onChange={(event) => setZeroBalanceAccounts(event.target.checked)} />
                        <label for="ImportZeroBalanceAccounts">Import zero balance accounts</label>
                    </div>
                    {/* <div class="flex items-center">
                        <input type="checkbox" id="ImportInactiveAccounts" name="ImportInactiveAccounts" class="form-checkbox mr-2" checked={inactiveAccounts} onChange={(event) => setInnactiveAccounts(event.target.checked)} />
                        <label for="ImportInactiveAccounts">Import inactive accounts</label>
                    </div> */}
                    <div class="flex items-center">
                        <input type="checkbox" id="syncHistoricalData" name="syncHistoricalData" class="form-checkbox mr-2" checked={historicalData} onChange={(event) => setHistoricalData(event.target.checked)}/>
                        <label for="syncHistoricalData">Sync historical data after initial import</label>
                    </div>
                    <div class="flex items-center mt-4">
                        <input type="checkbox" class="form-checkbox mr-2" checked={applyFilters} onChange={(event) => toggleFilters(event.target.checked)} />
                        <label>Apply filters:</label>
                    </div>
                    <div class="flex">
                        <table class="table-auto">
                            <thead>
                                <tr>
                                    <th class="px-4 py-2"></th>
                                    <th class="px-4 py-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="px-4 py-2">Class</td>
                                    <td class="px-4 py-2">
                                        <select
                                            disabled={!applyFilters}
                                            class="border border-gray-400 px-2 py-1 rounded"
                                            value={classFilter.id}
                                            onChange={(e) => updateClassFilter(e.target.value)}
                                        >
                                            <option value="">-- none selected --</option>
                                            {classList.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.fullyQualifiedName}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-4 py-2">Location</td>
                                    <td class="px-4 py-2">
                                    <select 
                                            disabled={!applyFilters}
                                            class="border border-gray-400 px-2 py-1 rounded"
                                            value={locationFilter.id}
                                            onChange={(e) => updateLocationFilter(e.target.value)}
                                        >
                                            <option value="">-- none selected --</option>
                                            {locationList.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.fullyQualifiedName}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-4 py-2">Customer</td>
                                    <td class="px-4 py-2">
                                    <select 
                                            disabled={!applyFilters}
                                            class="border border-gray-400 px-2 py-1 rounded"
                                            value={customerFilter.id}
                                            onChange={(e) => updateCustomerFilter(e.target.value)}
                                        >
                                            <option value="">-- none selected --</option>
                                            {customerList.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.fullyQualifiedName}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="flex items-center mt-4">
                        <label for="timezone" class="mr-2">Accounting Method:</label>
                        <input
                            class="mr-2"
                            type="radio"
                            value="1"
                            name="accountingMethod"
                            checked={accountingMethod === 1}
                            onChange={(e) => setAccountingMethod(Number(e.target.value))}
                        /> <span class="mr-2">Accrual</span>
                        <input 
                            class="mr-2"
                            type="radio"
                            value="2"
                            name="accountingMethod"
                            checked={accountingMethod === 2}
                            onChange={(e) => setAccountingMethod(Number(e.target.value))}
                        /> Cash
                    </div>
                    <div class="flex items-center mt-4">
                        <label for="timezone" class="mr-2">Import Options</label>
                        <select id="timezone" name="timezone" class="border border-gray-400 px-2 py-1 rounded"
                        value={importOption}
                        onChange={(e) => setImportOption(parseInt(e.target.value, 10))}>
                            <option selected value="0">Both</option>
                            <option value="1">Balance Sheet</option>
                            <option value="2">Income Statement</option>
                        </select>
                    </div>
                    <div class="flex items-center mt-8">
                        <button class="mr-4 py-2 px-2 border border-black-500 font-bold rounded" onClick={cancel}>
                            Cancel
                        </button>
                        <button class="bg-blue-500 hover:bg-blue-700 mr-4 py-2 px-2 text-white font-bold rounded" onClick={saveAndReturn}>
                            Save
                        </button>
                        <button class="bg-blue-500 hover:bg-blue-700 mr-4 py-2 px-2 text-white font-bold rounded" onClick={saveAndImport}>
                            Run Import
                        </button>
                    </div>
                </div>
            </>
          );
    }
}

export default ImportSetup;
