import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ImportAppQBO from './components/ImportAppQBO';
import ImportAppQBD from './components/ImportAppQBD';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CompleteAuthentication from './components/CompleteAuthentication';
import ImportSetup from './components/ImportSetup';
import ImportConnectionOptions from './components/ImportConnectionOptions';
import Authenticate from './components/Authenticate';
import EditImportSetup from './components/EditImportSetup';
import SelectAuthentication from './components/SelectAuthentication';
import SelectImportSetup from './components/SelectImportSetup';
import ErrorPage from './components/Error';
import logo from '../src/logo.png';
import DesktopSetup from './components/DesktopSetup';
import DownloadQWC from './components/DownloadQWC';
import SelectCompanyQBD from './components/SelectCompanyQBD';
import SelectImportSetupQBD from './components/SelectImportSetupQBD';
import ImportSetupQBD from './components/ImportSetupQBD';
import ImportConnectionOptionsQBD from './components/ImportConnectionOptionsQBD';
import EditImportSetupQBD from './components/EditImportSetupQBD';

const router = createBrowserRouter([
  {
    path: "/import-app/qbo",
    element: <ImportAppQBO />,
  },
  {
    path: "/import-app/qbd",
    element: <ImportAppQBD />,
  },
  {
    path: "/desktop-setup",
    element: <DesktopSetup />,
  },
  {
    path: "/desktop-select-company",
    element: <SelectCompanyQBD />
  },
  {
    path: "/desktop-select-import-setup/:companyId",
    element: <SelectImportSetupQBD />
  },
  {
    path: "/desktop-connection-options/:importId",
    element: <ImportConnectionOptionsQBD />
  },
  {
    path: "/desktop-import-setup/:companyId",
    element: <ImportSetupQBD />
  },
  {
    path: "/desktop-edit-import-setup/:importId",
    element: <EditImportSetupQBD />
  },
  {
    path: "/download-qwc",
    element: <DownloadQWC />,
  },
  {
    path: "/error",
    element: <ErrorPage />,
  },
  {
    path: "/complete-authentication",
    element: <CompleteAuthentication />,
  },
  {
    path: "/import-setup/:authId",
    element: <ImportSetup />,
  },
  {
    path: "/edit-import-setup/:authId/:importId",
    element: <EditImportSetup />,
  },
  {
    path: "/import-connection-options/:authId/:importId",
    element: <ImportConnectionOptions />,
  },
  {
    path: "/authenticate",
    element: <Authenticate />,
  },
  {
    path: "/select-authentication/:pgoCompanyId",
    element: <SelectAuthentication />,
  },
  {
    path: "/select-import-setup/:authId",
    element: <SelectImportSetup />,
  },
]);

function App() {
  return (
    <div className="mx-auto max-w-4xl">
      <header className="flex justify-between items-center py-4 px-6">
        <div>
          <img src={logo} alt="Logo" className="h-12 w-auto" />
        </div>
      </header>
      <RouterProvider router={router} />
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
