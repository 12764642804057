import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData, putData } from '../services/apiService';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';

function ImportConnectionOptionsQBD() {
    const [loading, setLoading] = useState(false);
    const [importEntity, setImportEntity] = useState({});
    const [lastImportDate, setLastImportDate] = useState();
    const navigate = useNavigate();
    const { importId } = useParams();

    useEffect(() => {
        setupComponent();
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once


    const setupComponent = async () => {

        var _importEntity = await fetchData(`/qbd/import/${importId}`);
        setImportEntity(_importEntity);

        if(_importEntity?.lastSuccessfulDateSynced){ 
            var lastSuccessfulDate = _importEntity?.lastSuccessfulDateSynced;
            
            // Create a new Date object from lastSuccessfulDate
            var localDateString = getTimezoneAdjustedDate(lastSuccessfulDate, -5);
            setLastImportDate(localDateString);
        }else{
            setLastImportDate("never");
        }
    };

    const getTimezoneAdjustedDate = (dateValue, offset) =>{
        const date = new Date(dateValue);

        // Get the UTC time
        const utcTime = date.getTime();

        const localTime = new Date(utcTime - ((offset * 60 * 60 * 1000)*-1));

        // Get the local date string
        const localDateString = localTime.toLocaleDateString();

        return localDateString;
    };

    const close = async () => {
        window.location.href = `${PGO_REDIRECT_URL}`;
    };

    const editImportSetup = async () => {
        navigate(`/desktop-edit-import-setup/${importId}`);
    };

    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
            </section>
        );
    }else{
        return (
            <>
                <div class="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                    <div class="text-xl font-medium text-black mb-4">Connection Options</div>
                    <div class="flex mt-4 items-center">
                        <button class="mr-4 py-2 px-2 border border-black-500 font-bold rounded" onClick={editImportSetup}>
                            Edit Import Setup
                        </button>
                    </div>
                    <div class="flex mt-4 items-center">
                        <p>Date Last Synced: {lastImportDate}</p>
                    </div>
                    <div class="flex mt-4 items-center">
                        <button  class="mr-4 py-2 px-2 border border-black-500 font-bold rounded" onClick={close}>
                            Close
                        </button>
                    </div>
                </div>
            </>
          );
    }
}

export default ImportConnectionOptionsQBD;