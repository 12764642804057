import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../services/apiService';
import { useEffect, useState } from 'react';
import { PGO_REDIRECT_URL } from '../core/constants';

function SelectAuthentication() {
    const { pgoCompanyId } = useParams();

    const [loading, setLoading] = useState(true);
    const [authList, setAuthList] = useState([]);
    const [selectedAuth, setSelectedAuth] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        loadAuthentications();
        setLoading(false);
        // If you need to clean up after the effect, you can return a function
        return () => {
          // Clean-up logic goes here
          // This function will be called when the component is unmounted or before the effect runs again
        };
      }, []); // Empty dependency array ensures the effect runs only once
      
    const loadAuthentications = async () => {
        var _entities = await fetchData(`/authentication/pgo/${pgoCompanyId}/list`);
        setAuthList(_entities);
    };

    const cancel = async () => {
        window.location.href = PGO_REDIRECT_URL;
    };

    const selectOption = async () => {

        if (!selectedAuth){
            alert("Please select an option");
            return;
        }
        navigate(`/select-import-setup/${selectedAuth.id}`);
    };

    if (loading){
        return (
            <section className="flex flex-col items-center mt-16">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
                    <p className="mt-4 text-gray-700">Loading...</p>
            </section>
        );
    }else{
        return (
            <div className="p-6 mx-auto bg-white shadow-md mt-8 flex flex-col space-x-4">
                <div className="text-xl font-medium text-black mb-4">Select Authentication</div>
                <div class="flex mt-8">
                    <table class="table-auto border">
                        <thead class="border">
                            <tr>
                                <th class="px-4 py-2">Select</th>
                                <th class="px-4 py-2">QuickBooks Online Company</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y">
                        {authList.map((item) => (
                            <tr class="divide-x" key={item.id}>
                                <td class="px-4 py-2">
                                    <input type="radio" name="selectedCompany" checked={selectedAuth == item} value={item.id} onChange={() => setSelectedAuth(item)} />
                                </td>
                                <td class="px-4 py-2">{item.qboCompanyName}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex items-center mt-8">
                    <button className="mr-2 py-2 px-2 border border-black-500 font-bold rounded" onClick={cancel}>
                        Cancel
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-700 mr-2 py-2 px-2 text-white font-bold rounded" onClick={selectOption}>
                        Select
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-700 py-2 px-2 text-white font-bold rounded" onClick={() => navigate(`/authenticate?addCompany=true`)}>
                        Add Authentication
                    </button>
                </div>
            </div>
          );
    }
}

export default SelectAuthentication;
